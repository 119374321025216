var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col", attrs: { id: "repositories" } }, [
    _c("h2", [_vm._v("Repositories")]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "shadow rounded p-3 mt-5 mb-5",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.onAddNewRepository.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newRepository,
                  expression: "newRepository",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                name: "repository",
                id: "repository",
                title: "repository to add",
                value: "",
                placeholder: "Type Github repository",
              },
              domProps: { value: _vm.newRepository },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.newRepository = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newBranch,
                  expression: "newBranch",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                name: "branch",
                id: "branch",
                title: "branch name",
                value: "",
                placeholder: "Type branch name",
              },
              domProps: { value: _vm.newBranch },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.newBranch = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary float-right",
                attrs: { title: "Add repository" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onAddNewRepository.apply(null, arguments)
                  },
                },
              },
              [_c("i", { staticClass: "fas fa-plus-circle" })]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("table", { staticClass: "table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.repositories, function (item) {
          return _c("tr", [
            _c("td", [_vm._v(_vm._s(item.name))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(item.branch))]),
            _vm._v(" "),
            _c("td", [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger btn-sm",
                  attrs: { title: "DELETE repository/branch!!!" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onDeleteRepository(item)
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-trash-alt" })]
              ),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [
          _c("i", { staticClass: "fab fa-github" }),
          _vm._v(" repository"),
        ]),
        _vm._v(" "),
        _c("th", [
          _c("i", { staticClass: "fas fa-code-branch" }),
          _vm._v(" branch"),
        ]),
        _vm._v(" "),
        _c("th", [
          _c("i", { staticClass: "fas fa-toolbox" }),
          _vm._v(" actions"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }