<template>
  <div id="signup" class="col d-flex flex-column justify-content-center">
    <div class="signup-content w-50">
      <div class="text-center pb-3">
        <span class="display-4">Sign up</span>
      </div>
      <div class="alert alert-info" role="alert" v-if="message">
        {{message}}
        <button type="button" class="close" aria-label="Close" @click.prevent="onMessageAlertClose">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="alert alert-danger" role="alert" v-if="errors">
        {{errors}}
        <button type="button" class="close" aria-label="Close" @click.prevent="onErrorAlertClose">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="validate-form" @submit.prevent="onSignUp">
        <div class="form-group">
          <input disabled class="w-100" required type="email" value="" v-model="signUpName" name="signUpName" id="singUpName" placeholder="email" autocomplete="username" />
        </div>
        <div class="form-group">
          <input class="w-100" required type="password" value="" v-model="signUpPassword" name="signUpPassword" id="signUpPassword" placeholder="password" autocomplete="new-password" />
        </div>
        <div class="form-group">
          <input class="w-100" required type="password" value="" v-model="signUpPasswordCopy" name="signUpPasswordCopy" id="signUpPasswordCopy" placeholder="confirm password" autocomplete="new-password" />
        </div>
        <div class="form-group">
          <input class="w-100" type="submit" value="SignUp">
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'signup',
  data () {
    return {
      signUpName: '',
      signUpPassword: '',
      signUpPasswordCopy: '',
      message: '',
      errors: null
    }
  },
  methods: {
    onSignUp (e) {
      let self = this

      Vue.axios({
        method: 'POST',
        url: '/signup',
          headers: {
            'Content-Type': 'application/json'
          },
        data: {
          user: {token: self.$route.query.token, password: this.signUpPassword}
        }
      })
        .then(result => {
          console.log(result.data);
          if (result.data) {
            self.$store.dispatch('createSession', result.data)
            self.$router.push(self.$route.query.redirect || { name: 'home' })
          }
        })
        .catch(e => {
          console.log(e)
        })

    },
    fetchData () {
      let self = this

      Vue.axios({
        method: 'GET',
        url: '/signup?token=' + self.$route.query.token,
      })
        .then(result => {
          console.log(result.data.data);
          if (result.data && result.data.data) {
            self.signUpName = result.data.data.email
          }
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  activated () {
    this.fetchData()
  }
}
</script>
<style lang="css">
</style>
