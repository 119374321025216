import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
    user: null
  },
  mutations: {
    login (state, user) {
      state.user = user
    },
    logout (state) {
      state.user = null
    }
  },
  getters: {
    currentUser (state) {
      return state.user
    },
    isLoggedIn (state) {
      return !!state.user
    }
  },
  actions: {
    createSession (context, user) {
      context.commit('login', user)
    },
    closeSession (context) {
      context.commit('logout')
    }
  },
  strict: debug
})
