var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c(
      "nav",
      { staticClass: "navbar navbar-expand-md navbar-dark fixed-top bg-dark" },
      [
        _c(
          "router-link",
          {
            staticClass: "navbar-brand",
            attrs: { to: { name: "home" }, title: "show revision list" },
          },
          [
            _c("img", {
              staticClass: "logo",
              attrs: { src: "images/OMASpecWorks_small_logo.png", alt: "logo" },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("OMA MD2HTML")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "navbar-toggler",
            attrs: {
              type: "button",
              "data-toggle": "collapse",
              "data-target": "#navbarCollapse",
              "aria-controls": "navbarCollapse",
              "aria-expanded": "false",
              "aria-label": "Toggle navigation",
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleMenyClick.apply(null, arguments)
              },
            },
          },
          [_c("span", { staticClass: "navbar-toggler-icon" })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "collapse navbar-collapse",
            class: { show: _vm.showMeny },
            attrs: { id: "navbarCollapse" },
          },
          [
            _c("ul", { staticClass: "navbar-nav mr-auto" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.haveSession,
                      expression: "haveSession",
                    },
                  ],
                  staticClass: "nav-item",
                  class: { active: _vm.isActiveLink("repositories") },
                  attrs: { title: "show Repositories page" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleMenyClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "repositories" } },
                    },
                    [
                      _c("i", { staticClass: "fab fa-github" }),
                      _vm._v(" Repositories"),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.haveSession,
                      expression: "haveSession",
                    },
                  ],
                  staticClass: "nav-item",
                  class: { active: _vm.isActiveLink("users") },
                  attrs: { title: "show Users page" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleMenyClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "users" } },
                    },
                    [_c("i", { staticClass: "fas fa-users" }), _vm._v(" Users")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.haveSession,
                      expression: "haveSession",
                    },
                  ],
                  staticClass: "nav-item",
                  class: { active: _vm.isActiveLink("invitations") },
                  attrs: { title: "show Invitations page" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleMenyClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "invitations" } },
                    },
                    [
                      _c("i", { staticClass: "fas fa-mail-bulk" }),
                      _vm._v(" Invitations"),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("div", {}, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.haveSession,
                      expression: "!haveSession",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleMenyClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "signin" },
                        title: "sign in to administrative portal",
                      },
                    },
                    [_vm._v("SignIn")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.haveSession,
                      expression: "haveSession",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleMenyClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "signout" },
                        title: "sign out from administrative portal",
                      },
                    },
                    [_vm._v("SignOut")]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "main container" }, [
      _c(
        "div",
        { staticClass: "row" },
        [_c("keep-alive", [_c("router-view")], 1)],
        1
      ),
    ]),
    _vm._v(" "),
    _vm._m(2),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item" }, [
      _c(
        "a",
        {
          staticClass: "nav-link",
          attrs: {
            href: "https://github.com/OpenMobileAlliance/LwM2M/wiki/MD2HTML-Guidelines",
            target: "_blank",
          },
        },
        [_vm._v("Guidelines")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "navbar-nav ml-auto pr-3" }, [
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: {
              href: "mailto:helpdesk@omaorg.org?subject=MD2HTML TOOL user inquiry",
              title: "Send email to OMA help desk",
            },
          },
          [_vm._v("Contact")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "footer" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("span", { staticClass: "text-muted" }, [
              _vm._v("OMA MD2HTML tool."),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "text-muted far fa-copyright" }),
            _vm._v(" "),
            _c("span", { staticClass: "text-muted" }, [_vm._v("2024")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col text-center" }, [
            _c("span", { staticClass: "text-muted" }, [
              _vm._v("Content gets updated every 5 minutes"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col text-right" }, [
            _c("span", { staticClass: "text-muted" }, [_vm._v("version:")]),
            _vm._v(" "),
            _c("span", { staticClass: "text-muted" }, [_vm._v("3.1.3")]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }