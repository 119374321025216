<template>
  <div id="repositories" class="col">
    <h2>Repositories</h2>
    <form class="shadow rounded p-3 mt-5 mb-5" @submit.prevent="onAddNewRepository">
      <div class="form-row">
        <div class="col-md-6">
          <input class="form-control" type="text" name="repository" id="repository" title="repository to add"
            v-model="newRepository" value="" placeholder="Type Github repository" >
        </div>
          <div class="col-md-4">
            <input class="form-control" type="text" name="branch" id="branch" title="branch name"
              v-model="newBranch" value="" placeholder="Type branch name">
          </div>
          <div class="col">
            <button class="btn btn-primary float-right"
              title="Add repository"
              @click.prevent="onAddNewRepository">
              <i class="fas fa-plus-circle"></i>
            </button>
          </div>
      </div>
    </form>
    <table class="table">
      <thead>
        <tr>
          <th scope="col"><i class="fab fa-github"></i> repository</th>
          <th><i class="fas fa-code-branch"></i> branch</th>
          <th><i class="fas fa-toolbox"></i> actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in repositories">
          <td>{{item.name}}</td>
          <td>{{item.branch}}</td>
          <td>
            <button @click.prevent="onDeleteRepository(item)"
              title="DELETE repository/branch!!!"
              class="btn btn-danger btn-sm">
              <i class="fas fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'repositories',
  data () {
    return {
      repositories: [],
      newRepository: '',
      newBranch: ''
    }
  },
  methods: {
    onDeleteRepository: function (data) {
      console.log(data)
      var self = this

      Vue.axios({
        url: '/api/repositories/' + data.id,
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token
        }
      })
        .then(result => {
          console.log(result)
          self.fetchData()
        })
        .catch(function (e) {
          console.log(e.message)
          self.fetchData()
        })
    },
    onAddNewRepository: function () {
      console.log(this.newRepository, this.newBranch)
      var self = this

      Vue.axios({
        url: '/api/repositories',
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token
        },
        data: {
          repository: this.newRepository,
          branch: this.newBranch
        }
      })
        .then(result => {
          console.log(result)
          self.newRepository = ''
          self.newBranch = ''
          self.fetchData()
        })
        .catch(function (e) {
          console.log(e.message)
          self.newRepository = ''
          self.newBranch = ''
        })
    },
    fetchData: function () {
      var self = this

      Vue.axios({
        url: '/api/repositories',
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token
        }
      })
        .then(function (result) {
          if (result.data) {
            if (result.data.data) {
              self.repositories = result.data.data.repositories
            } else if (result.data.errors) {
              self.errors = result.data.errors
            } else {
              console.log('this should not happen !?')
            }
          }
        })
        .catch(function (e) {
          console.log(e.message)
        })
    }
  },
  activated: function () {
    this.fetchData()
  }
}
</script>
<style lang="css">
</style>
