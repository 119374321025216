<template>
  <div id="signout">
  </div>
</template>
<script>
export default {
  name: 'signout',
  data () {
    return {}
  },
  activated () {
    this.$router.replace('/')
    this.$store.dispatch('closeSession')
  }
}
</script>
<style lang="css">
</style>
