<template>
  <div id="invitations" class="col">
    <h2>Invitations</h2>
    <div class="container">
      <div class="row" v-show="!showInvitationForm && !showInvitationURLForm">
        <div class="col">
          <button class="btn btn-info float-right" @click.prevent="showInvitationFormClick" title="add new invitation">
            <i class="fas fa-plus-circle"></i>
          </button>
        </div>
      </div>
      <div class="row" v-show="showInvitationForm">
        <div class="media col">
          <div class="media-body">
            <h5>New Invitation</h5>
            <form class="" @submit.prevent="sendInvitationClick">
              <div class="form-group">
                <input class="form-control" required type="text" value="" v-model="invitationEmail"
                  name="invitation-email" id="invitation-email" 
                  placeholder="email" autocomplete="user" />
              </div>
              <div class="form-group">
                <textarea class="form-control" rows="10" value="" v-model="invitationMessage"
                  name="invitation-message" id="invitations-message" placeholder="message"></textarea>
              </div>
              <div class="form-group">
                <span @click.prevent="showInvitationFormClick" class="btn btn-link">Cancel</span>
                <input class="btn  btn-info float-right" type="submit" value="Send">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-show="showInvitationURLForm">
      <div class="media col">
        <div class="media-body">
          <h4>Invitation: {{getInvitationID()}} </h4>
          <div class="input-group mb-3 pt-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-at"></i>&nbsp; email</span>
              </div>
              <div class="form-control">
                {{ getInvitationEmail() }}
              </div>
          </div>
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-link"></i>&nbsp; link
                </span>
              </div>
              <div class="form-control">
                {{ getInvitationURL() }}
              </div>
              <div class="input-group-append">
                <button class="input-group-text"
                  title="copy to clipboard"
                  v-clipboard="() => getInvitationURL()">
                  <i class="fas fa-copy"></i>
                </button>
              </div>
          </div>
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-hourglass-half"></i>&nbsp; expire
                </span>
              </div>
              <div class="form-control">
                {{ getSelectedItemValidTo() }}
              </div>
          </div>
          <span @click.prevent="hideInvitation" class="btn btn-link">Cancel</span>
        </div>
      </div>
    </div>
    <table class="table mt-3" v-show="showInvitationTable">
      <thead>
        <tr>
          <th><i class="fas fa-at"></i> email</th>
          <th><i class="fas fa-thermometer-half"></i> status</th>
          <th><i class="fas fa-hourglass-half"></i> expire</th>
          <th><i class="fas fa-history"></i> updated </th>
          <th><i class="fas fa-toolbox"></i> actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in invitations">
          <td>{{item.email}}</td>
          <td>{{item.status}}</td>
          <td>{{item.validTo | niceTime}}</td>
          <td>{{item.updatedAt | niceTime}}</td>
          <td>
            <button class="btn btn-warning"
              title="resend invitation"
              :disabled="canResendInvitation(item)"
              @click.prevent="resendInvitation(item)">
              <i class="fas fa-retweet"></i>
            </button>
            <button class="btn btn-info"
              title="show invitation"
              :disabled="canResendInvitation(item)"
              @click.prevent="showInvitation(item)">
              <i class="fas fa-eye"></i>
            </button>
            <button @click.prevent="deleteInvitation(item)"
              title="delete invitation"
              class="btn btn-danger float-right">
              <i class="fas fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'invitations',
  data () {
    return {
      showInvitationForm: false,
      showInvitationTable: true,
      showInvitationURLForm: false,
      invitationEmail: '',
      invitationMessage: '',
      invitations: [],
      selectedItem: null
    }
  },
  methods: {
    getInvitationID () {
      return this.selectedItem ? this.selectedItem.id : ''
    },
    getInvitationURL () {
      return this.selectedItem ? window.location.origin + '/#/signup?token=' + this.selectedItem.token : ''
    },
    getInvitationEmail () {
      return this.selectedItem ? this.selectedItem.email : ''
    },
    getSelectedItemValidTo () {
      return this.selectedItem ? this.selectedItem.validTo : ''
    },
    canResendInvitation (value) {
      return 'accepeted' === value.status
    },
    clearForm () {
      this.invitationEmail = ''
      this.invitationMessage = ''
    },
    toggleShotForm () {
      this.showInvitationForm = !this.showInvitationForm
      this.showInvitationTable = !this.showInvitationTable
      if (this.showInvitationForm) {
        this.clearForm()
      }
    },
    showInvitationFormClick (e) {
      this.toggleShotForm()
    },
    sendInvitationClick () {
      this.toggleShotForm()
      this.sendInvitation()
    },
    sendInvitation () {
      var self = this
      Vue.axios({
        url: '/api/invitations',
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token,
          'Content-Type': 'application/json'
        },
        data: {
          email: self.invitationEmail,
          message: self.invitationMessage
        }
      })
        .then(result => {
          console.log(result.data);
          self.fetchData()
        })
        .catch(e => {
          console.log(e.message)
        })
    },
    resendInvitation (value) {
      var self = this
      Vue.axios({
        url: '/api/invitations/' + value.id,
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token,
          'Content-Type': 'application/json'
        }
      })
        .then(result => {
          console.log(result.data);
          self.fetchData()
        })
        .catch(e => {
          console.log(e.message)
        })
    },
    showInvitation (item) {
      this.showInvitationURLForm = true
      this.showInvitationTable = false
      this.selectedItem = item
    },
    hideInvitation () {
      this.showInvitationURLForm = false
      this.showInvitationTable = true
      this.selectedItem = null
    },
    deleteInvitation (value) {
      var self = this
      Vue.axios({
        url: '/api/invitations/' + value.id,
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token,
          'Content-Type': 'application/json'
        }
      })
        .then(result => {
          console.log(result.data);
          self.fetchData()
        })
        .catch(e => {
          console.log(e.message)
        })
    },
    fetchData () {
      var self = this

      Vue.axios({
        url: '/api/invitations',
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token,
          'Content-Type': 'application/json'
        }
      })
        .then(result => {
          if (result.data) {
            if (result.data.data) {
              self.invitations = result.data.data.invitations
            } else if (result.data.errors) {
              self.errors = result.data.errors
            } else {
              console.log('this should not happen !?')
            }
          }
        })
        .catch(e => {
          console.log(e.message)
        })
    }
  },
  activated () {
    this.clearForm()
    this.fetchData()
  },
  deactivated () {
  }
}
</script>
<style lang="css">
</style>
