import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from './pages/home'
import UsersPage from './pages/users'
import InvitationsPage from './pages/invitations'
import SignInPage from './pages/signin'
import SignUpPage from './pages/signup'
import SignOutPage from './pages/signout'
import RepositoriesPage from './pages/repositories'
import DiffViewPage from './pages/diff-view'
import store from './store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignInPage
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUpPage
  },
  {
    path: '/signout',
    name: 'signout',
    component: SignOutPage
  },
  {
    path: '/repositories',
    name: 'repositories',
    component: RepositoriesPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: UsersPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/invitations',
    name: 'invitations',
    component: InvitationsPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/diff-view/:repo/:branch/:l/vs/:r',
    name: 'diff-view',
    component: DiffViewPage
  }
]

const router = new VueRouter({
  routes: routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(function (record) { return record.meta.requiresAuth }) && !store.getters.isLoggedIn) {
    next({ path: '/signin', query: { redirect: to.fullPath } })
  } else {
    next()
  }
})

export default router
