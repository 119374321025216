var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col", attrs: { id: "users" } }, [
    _c("h2", [_vm._v("Users")]),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.updateType,
              expression: "updateType",
            },
          ],
          staticClass: "row",
        },
        [
          _c("div", { staticClass: "col" }, [
            _c(
              "form",
              {
                staticClass: "validate-form",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.onUpdateUser.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c("h4", [
                    _vm._v("Change pasword for: " + _vm._s(_vm.updateEmail)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.updatePassword,
                        expression: "updatePassword",
                      },
                    ],
                    attrs: {
                      required: "",
                      type: "password",
                      value: "",
                      name: "updatePassword",
                      id: "updatePassword",
                      placeholder: "password",
                      autocomplete: "new-password",
                    },
                    domProps: { value: _vm.updatePassword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.updatePassword = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.updatePasswordCopy,
                        expression: "updatePasswordCopy",
                      },
                    ],
                    attrs: {
                      required: "",
                      type: "password",
                      value: "",
                      name: "updatePasswordCopy",
                      id: "updatePasswordCopy",
                      placeholder: "confirm password",
                      autocomplete: "new-password",
                    },
                    domProps: { value: _vm.updatePasswordCopy },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.updatePasswordCopy = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "span",
                    {
                      staticClass: "btn btn-link",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onCancelUpdate.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" or "),
                  _c("input", {
                    staticClass: "btn  btn-info",
                    attrs: { type: "submit", value: "Update" },
                  }),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "table",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.updateType,
            expression: "!updateType",
          },
        ],
        staticClass: "table mt-3",
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.users, function (item) {
            return _c("tr", [
              _c("td", [_vm._v(_vm._s(item.email))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(item.role))]),
              _vm._v(" "),
              _c("td", [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !item.active,
                        expression: "!item.active",
                      },
                    ],
                    staticClass: "btn btn-outline-danger",
                    attrs: { title: "activate/deactivate user" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onActivateUser(item)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-toggle-off" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: item.active,
                        expression: "item.active",
                      },
                    ],
                    staticClass: "btn btn-outline-success",
                    attrs: { title: "activate/deactivate user" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onDeactivateUser(item)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-toggle-on" })]
                ),
              ]),
              _vm._v(" "),
              _c("td", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info",
                    attrs: {
                      title: "show User info",
                      disabled: !_vm.canEditUser(item.id),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onEditUser(item)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-edit" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger float-right",
                    attrs: {
                      title: "delete User",
                      disabled: !_vm.canDeleteUser(item.id),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onDeleteUser(item)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-trash-alt" })]
                ),
              ]),
            ])
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_c("i", { staticClass: "fas fa-at" }), _vm._v(" email")]),
        _vm._v(" "),
        _c("th", [
          _c("i", { staticClass: "fas fa-user-tag" }),
          _vm._v(" role"),
        ]),
        _vm._v(" "),
        _c("th", [
          _c("i", { staticClass: "fab fa-nintendo-switch" }),
          _vm._v(" active"),
        ]),
        _vm._v(" "),
        _c("th", [
          _c("i", { staticClass: "fas fa-toolbox" }),
          _vm._v(" actions"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }