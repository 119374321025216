<template>
  <div id="app">
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <router-link :to="{ name:'home' }" class="navbar-brand" title="show revision list">
        <img class="logo" src="images/OMASpecWorks_small_logo.png" alt="logo">
        <span>OMA MD2HTML</span>
      </router-link>
      <button class="navbar-toggler"
        @click.prevent="toggleMenyClick"
        type="button"
        data-toggle="collapse" data-target="#navbarCollapse"
        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div :class="{show: showMeny}" class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="https://github.com/OpenMobileAlliance/LwM2M/wiki/MD2HTML-Guidelines"
              target="_blank">Guidelines</a>
          </li>
          <li :class="{ active: isActiveLink('repositories') }"
            title="show Repositories page"
            @click.prevent="toggleMenyClick"
            class="nav-item" v-show="haveSession">
            <router-link :to="{ name:'repositories' }" class="nav-link"><i class="fab fa-github"></i> Repositories</router-link>
          </li>
          <li :class="{ active: isActiveLink('users') }"
            title="show Users page"
            @click.prevent="toggleMenyClick"
            class="nav-item" v-show="haveSession">
            <router-link :to="{ name: 'users' }" class="nav-link"><i class="fas fa-users"></i> Users</router-link>
          </li>
          <li :class="{ active: isActiveLink('invitations') }"
            title="show Invitations page"
            @click.prevent="toggleMenyClick"
            class="nav-item" v-show="haveSession">
            <router-link :to="{ name: 'invitations' }" class="nav-link"><i class="fas fa-mail-bulk"></i> Invitations</router-link>
          </li>
        </ul>
        <ul class="navbar-nav ml-auto pr-3">
          <li class="nav-item">
            <a class="nav-link" href="mailto:helpdesk@omaorg.org?subject=MD2HTML TOOL user inquiry"
              title="Send email to OMA help desk">Contact</a>
          </li>
        </ul>
        <div class="">
          <div v-show="!haveSession" @click.prevent="toggleMenyClick">
            <router-link :to="{ name:'signin' }"
              title="sign in to administrative portal">SignIn</router-link>
          </div>
          <div v-show="haveSession" @click.prevent="toggleMenyClick">
            <router-link :to="{ name:'signout' }" title="sign out from administrative portal">SignOut</router-link>
          </div>
        </div>
      </div>
    </nav>
    <div class="main container" >
      <div class="row">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
    </div>
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col">
            <span class="text-muted">OMA MD2HTML tool.</span>
            <span class="text-muted far fa-copyright"></span> <span class="text-muted">2024</span>
          </div>
          <div class="col text-center">
            <span class="text-muted">Content gets updated every 5 minutes</span>
          </div>
          <div class="col text-right">
            <span class="text-muted">version:</span> <span class="text-muted">3.1.3</span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: 'app',
  data () {
    return {
      showMeny: false
    }
  },
  computed: {
    haveSession () {
      return !!this.$store.getters.currentUser
    }
  },
  methods: {
    isActiveLink (linkName) {
      return this.$route.name === linkName
    },
    toggleMenyClick () {
      this.showMeny = !this.showMeny
    }
  }
}
</script>
<style lang="css">
</style>
