var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "col d-flex flex-column justify-content-center",
      attrs: { id: "signup" },
    },
    [
      _c("div", { staticClass: "signup-content w-50" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm.message
          ? _c(
              "div",
              { staticClass: "alert alert-info", attrs: { role: "alert" } },
              [
                _vm._v("\n      " + _vm._s(_vm.message) + "\n      "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button", "aria-label": "Close" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onMessageAlertClose.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.errors
          ? _c(
              "div",
              { staticClass: "alert alert-danger", attrs: { role: "alert" } },
              [
                _vm._v("\n      " + _vm._s(_vm.errors) + "\n      "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button", "aria-label": "Close" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onErrorAlertClose.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "validate-form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSignUp.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.signUpName,
                    expression: "signUpName",
                  },
                ],
                staticClass: "w-100",
                attrs: {
                  disabled: "",
                  required: "",
                  type: "email",
                  value: "",
                  name: "signUpName",
                  id: "singUpName",
                  placeholder: "email",
                  autocomplete: "username",
                },
                domProps: { value: _vm.signUpName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.signUpName = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.signUpPassword,
                    expression: "signUpPassword",
                  },
                ],
                staticClass: "w-100",
                attrs: {
                  required: "",
                  type: "password",
                  value: "",
                  name: "signUpPassword",
                  id: "signUpPassword",
                  placeholder: "password",
                  autocomplete: "new-password",
                },
                domProps: { value: _vm.signUpPassword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.signUpPassword = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.signUpPasswordCopy,
                    expression: "signUpPasswordCopy",
                  },
                ],
                staticClass: "w-100",
                attrs: {
                  required: "",
                  type: "password",
                  value: "",
                  name: "signUpPasswordCopy",
                  id: "signUpPasswordCopy",
                  placeholder: "confirm password",
                  autocomplete: "new-password",
                },
                domProps: { value: _vm.signUpPasswordCopy },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.signUpPasswordCopy = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm._m(1),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center pb-3" }, [
      _c("span", { staticClass: "display-4" }, [_vm._v("Sign up")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("input", {
        staticClass: "w-100",
        attrs: { type: "submit", value: "SignUp" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }